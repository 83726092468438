import React, { Component, lazy, useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import { Route, Router, useLocationProperty,navigate  } from 'wouter';
import { Loader } from "rsuite";
import logo from "./logo.svg";
import "./App.css";
import "./styles/Nrjwtr.css";
import { NrjRequire } from "./utilities/NrjRequire";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { NrjLgn } from './components/NrjLgn';

const LzNrjLgn = React.lazy(() => import("./components/NrjLgn"));
const LzNrjSlDts = React.lazy(()=>import("./components/pharma/NrjSlDts"))
const LzNrjWhlPur = React.lazy(()=> import("./components/pharma/purchase/NrjWhlPur"))
const LzNrjPhrmPurSl = React.lazy(()=>import ("./components/pharma/NrjPhrmPurSl"))
const LzNrjBrndSrch = React.lazy(()=>import("./components/pharma/master/NrjBrndSrch"))
const LzNrjWhlSl = React.lazy(()=> import("./components/pharma/sale/NrjWhlSl"))
const LzNrjMstrPrd = React.lazy(()=> import("./components/pharma/master/NrjMstrPrd"))
const LzLstSlbl = React.lazy(()=>import("./components/pharma/sale/LstSlbl") )
const LzPrdPurSl = React.lazy(()=>import ('./components/pharma/sale/NrjPrdPurSl'))
const LzNrjCstWPrftLs = React.lazy(()=> import("./components/pharma/sale/NrjCstWPrftLs"))
const App = () => {
  // const hashLocation = () => window.location.hash.replace(/^#/, "") || "/";
  // const hashNavigate = (to:any) => navigate('#' + to);
  // const useHashLocation = () => {
  //   const location = useLocationProperty(hashLocation);
  //   return [location, hashNavigate];
  // };
  // const [textDts, setTextDts] = useState("");

  // const onChangeDts = (data:string)=>{
  //   let dta : string = textDts;
  //   let fldN : any = utilities(2,data, "");
  //   if (textDts){
  //     dta = textDts + "=";
  //     let d : any =  utilities(1,dta,fldN);
  //     if (d){
  //       dta = d;
  //     }

  //   }
  //   dta += data;

  //   setTextDts(dta);

  // }

  useEffect(() => {
    document.title = "Neerja Associates Pvt Ltd";
    // sessionStorage.setItem("cmpid", "104506")
    // sessionStorage.setItem("usrid", "101")
  });
  // const queryClient = new QueryClient();
  return (
    <div>
      <BrowserRouter>
        
          <Routes>
            <Route
              path="/phrmsl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjSlDts />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/whpur"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjWhlPur />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/whsle"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjWhlSl />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/whpursl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjPhrmPurSl />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            
             {/* <Route
              path="/phrmpurlst"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                  <LzNrjWhslPur />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
             <Route
              path="/phrmprd"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                  <LzNrjBrndSrch />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/brnds"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjMstrPrd />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <LzNrjLgn></LzNrjLgn>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/lstslbl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzLstSlbl></LzLstSlbl>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
             <Route
              path="/pursl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzPrdPurSl />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/custws"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjCstWPrftLs />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
          </Routes>
        {/* </QueryClientProvider>  */}
      </BrowserRouter>
    </div>
  );
};
export default App;
